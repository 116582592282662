<template lang="">
  <Card>
    <template #header> Görüntüleme Grafikleri </template>
    <div class="row">
      <div class="col-md-6">
        <div class="form-group">
          <label for="">Sahibinden sitesi görünütlenme sayısı</label>
          <input type="text" class="form-control" />
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label for="">Hürriyet Emlak görüntülenme sayısı</label>
          <input type="text" class="form-control" />
        </div>
      </div>
    </div>
    <div class="d-flex w-100 justify-content-center align-items-center flex-column">
        <div class="d-flex align-items-center">
            <span class="badge yellow"></span>
            <p class="ml-1">Sahibinden : 0</p>
        </div>
        <div class="d-flex align-items-center">
            <span class="badge red"></span>
            <p class="ml-1">Hürriyet Emlak : 0</p>
        </div>
        <div class="d-flex align-items-center">
            <span class="badge primary"></span>
            <p class="ml-1">Bankamdan : 39</p>
        </div>
        <hr class="bg-dark h-4 w-100" />
        <div class="d-flex align-items-center">
            <span class="badge primary"></span>
            <p class="ml-1">Toplam : 39</p>
        </div>
    </div>
    <div class="form-group d-flex">
        <button class="btn btn-success ml-auto">Kaydet</button>
      </div>
  </Card>
</template>
<script>
export default {};
</script>
<style lang="scss" scoped>

      .badge {
        display: inline-block;
        width: 1rem;
        height: 1rem;
        border-radius: 50%;
        padding: 0 !important;
  
        &.green {
          background-color: #44b6ae;
        }
        &.yellow {
          background-color: #ffac00;
        }
        &.red {
          background-color: red;
        }
        &.primary{
            background-color: $dveb-primary;
        }
      }
    
</style>
